import { FC, useEffect, useMemo, useState } from 'react';
import Tab from '../../ui/components/Tab/Tab';
import { alertApi, useAlertGetAlerts } from '../../../api/alert';
import { flattenInfiniteQueryResult } from '../../../utils/helpers/react-query.helper';
import Pagination from '../../../containers/Pagination';
import Alert from './Alert/Alert';
import AlertFilter, { defaultStateFilters } from './Alert/AlertFilter';
import AlertAppliedFilters from './Alert/AlertAppliedFilters';
import { UseInfiniteQueryResult, useMutation, useQueryClient } from 'react-query';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { IAlertFiltersUpdate, IAlertGetAlertsResponse } from '../../../api/dtos/alert';
import { every, isEmpty } from 'lodash';
import { AxiosError, AxiosResponse } from 'axios';
import { Tooltip } from '../../../ui/components/Tooltip';
import Skeleton from 'react-loading-skeleton';
import UpdateStageorAssigneeModal from '../UpdateStageorAssigneeModal';
import { useWorkSpaceGetWorkFlowMembers } from '../../../api/workspaces';
import { useDebounce } from 'use-debounce';
import { IWorkSpaceMembers } from '../../../api/dtos/workspaces';
import StageBadge from '../../../ui/components/Badges/StageBadge/StageBadge';
import { useStagesListLiteGet } from '../../../api/stages';
import { getColorMappingStageOptions } from '../../../utils/helpers/stageOptionsColor';
import UserBadge from '../../../ui/components/Badges/UserBadge/UserBadge';
import { IOption } from '../../../components/ui/components/Select/SingleSelect';
import { IStageOptions } from './Alert/AlertButtonPanel/AlertButtonPanel';
import { IStageListLite } from '../../../api/dtos/stages';
import { useAuth } from '../../../modules/auth';
import { getErrorMessage } from '../../../utils/helpers/helperFunctions';

interface AlertsProps {
  type: 'address' | 'transaction' | 'customer';
  id: string | number;
  // custId is numeric id of customer used for resolving all alerts
  custId?: number;
  setAlerts?: (alerts: number) => void;
  setAlertDetails?: (alert: number) => void;
  entity_type?: string;
  showLoading?: boolean;
  showFullHeight?: boolean;
  noDataAfterLoad?: () => void;
}

const Alerts: FC<AlertsProps> = ({
  type,
  id,
  setAlerts,
  setAlertDetails,
  entity_type,
  showLoading = false,
  showFullHeight = false,
  noDataAfterLoad,
}) => {
  const { state } = useAuth();
  const [tab, setTab] = useState<number>(0);
  const queryClient = useQueryClient();
  const [openFilters, setOpenFilters] = useState(defaultStateFilters as IAlertFiltersUpdate);
  const [assignedFilters, setAssignedFilters] = useState(defaultStateFilters as IAlertFiltersUpdate);
  const [unAssignedFilters, setUnAssignedFilters] = useState(defaultStateFilters as IAlertFiltersUpdate);
  const [closedFilters, setClosedFilters] = useState(defaultStateFilters as IAlertFiltersUpdate);
  const [staleFilters, setStaleFilters] = useState(defaultStateFilters as IAlertFiltersUpdate);
  const [isBulkUpdate, setIsBulkUpdate] = useState<boolean>(false);
  const [selectedOptionId, setSelectedOptionId] = useState<number>();
  const [openModal, setOpenModal] = useState('');
  const [selectedOption, setSelectedOption] = useState<IStageOptions>();
  const [currentOption, setCurrentOption] = useState<IStageOptions>();
  const [selectedAlertId, setSelectedAlertId] = useState<number | string>();
  const [isAllAlertsSelected, setIsAllAlertsSelected] = useState(false);
  const [selectedAlerts, setSelectedAlerts] = useState<number[]>([]);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebounce(search, 500);
  const [comment, setComment] = useState('');
  const [closedStage, setClosedStage] = useState<IStageListLite>();

  const openAlertsQuery = useAlertGetAlerts({
    id,
    type,
    status: [0],
    filters: openFilters,
    entity_type,
    closed: true,
  });
  const assignedAlertsQuery = useAlertGetAlerts({
    id,
    type,
    entity_type,
    assigned_to: state.userProfile?.id,
    filters: assignedFilters,
    assigned: true,
  });
  const unAssignedAlertsQuery = useAlertGetAlerts({
    id,
    type,
    filters: unAssignedFilters,
    entity_type,
    assigned_to: 'None',
    assigned: true,
  });
  const closedAlertsQuery = useAlertGetAlerts(
    {
      id,
      type,
      filters: closedFilters,
      status: [Number(closedStage?.id)],
      entity_type,
      closed: true,
    },
    {
      enabled: !!closedStage,
    }
  );
  const staleAlertsQuery = useAlertGetAlerts({
    id,
    type,
    is_stale: true,
    filters: staleFilters,
    entity_type,
  });
  const assignedToOptionsQuery = useWorkSpaceGetWorkFlowMembers({
    q: debouncedSearch,
    is_active: true,
  });
  const [, assignedToOptions]: [number, IWorkSpaceMembers[]] = flattenInfiniteQueryResult(
    assignedToOptionsQuery?.data
  );
  const stagesListLiteQuery = useStagesListLiteGet();
  const { mutate: bulkAlertStatusUpdate, isLoading: isLoadingUpdateStageorAssignee } = useMutation(
    alertApi.updateMultipleAlerts,
    {
      onSuccess: () => {
        const key = ['alertApi.getAlerts', id];
        queryClient.invalidateQueries(key);
        toast.success('Alerts Updated Successfully');
        setOpenModal('');
        setComment('');
        setSelectedOption(null);
        setSelectedOptionId(null);
        setCurrentOption(null);
      },
      onError(err: AxiosError<{ error: string }>) {
        toast.error(getErrorMessage(err));
      },
    }
  );
  const { mutate: mutateAlert } = useMutation(alertApi.updateAlert, {
    onSuccess: () => {
      const key = ['alertApi.getAlerts', id];
      queryClient.invalidateQueries(key);
      toast.success('Alerts Updated Successfully');
      setOpenModal('');
      setComment('');
      setSelectedOption(null);
      setSelectedOptionId(null);
      setCurrentOption(null);
    },
    onError(err: AxiosError<{ error: string }>) {
      toast.error(getErrorMessage(err));
    },
  });
  const getStageOptions = (stageData) => {
    return stageData?.map((r) => {
      return {
        id: r.id,
        value: r.name,
        label: r.name,
      };
    });
  };

  const getAssignedToOptions = (assignedToData: IWorkSpaceMembers[]) => {
    return assignedToData?.map((r) => {
      return {
        value: r.email,
        label: r.email,
      };
    });
  };

  useEffect(() => {
    setClosedStage(stagesListLiteQuery?.data?.data.results.find((item) => item.is_final_stage));
  }, [stagesListLiteQuery]);

  const stageOptionsData = getStageOptions(stagesListLiteQuery?.data?.data?.results);
  const assignedToOptionsData = getAssignedToOptions(assignedToOptions);

  const [openAlertsCount, openAlertsData] = flattenInfiniteQueryResult(openAlertsQuery.data);
  const [assignedAlertsCount, assignedAlertsData] = flattenInfiniteQueryResult(assignedAlertsQuery.data);
  const [unAssignedAlertsCount, unAssignedAlertsData] = flattenInfiniteQueryResult(
    unAssignedAlertsQuery.data
  );
  const [closedAlertsCount, closedAlertsData] = flattenInfiniteQueryResult(closedAlertsQuery.data);
  const [staleAlertsCount, staleAlertsData] = flattenInfiniteQueryResult(staleAlertsQuery.data);
  const stageColorMap = getColorMappingStageOptions(stagesListLiteQuery?.data?.data?.results);

  const isLoading =
    openAlertsQuery.isLoading ||
    assignedAlertsQuery.isLoading ||
    unAssignedAlertsQuery.isLoading ||
    closedAlertsQuery.isLoading ||
    staleAlertsQuery.isLoading;

  const onApplyFilter = (filters: typeof defaultStateFilters) => {
    if (tab === 0) setOpenFilters(filters);
    else if (tab === 1) setAssignedFilters(filters);
    else if (tab === 2) setUnAssignedFilters(filters);
    else if (tab === 3) setClosedFilters(filters);
    else if (tab === 4) setStaleFilters(filters);
  };

  const currentFilters = useMemo(() => {
    if (tab === 0) return openFilters;
    else if (tab === 1) return assignedFilters;
    else if (tab === 2) return unAssignedFilters;
    else if (tab === 3) return closedFilters;
    else if (tab === 4) return staleFilters;
  }, [openFilters, assignedFilters, unAssignedFilters, closedFilters, staleFilters, tab]);

  useEffect(() => {
    setAlerts(openAlertsCount);
  }, [openAlertsCount, setAlerts]);

  useEffect(() => {
    if (
      !isLoading &&
      openAlertsCount === 0 &&
      assignedAlertsCount === 0 &&
      unAssignedAlertsCount === 0 &&
      closedAlertsCount === 0 &&
      staleAlertsCount === 0
    ) {
      noDataAfterLoad?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoading,
    openAlertsCount,
    assignedAlertsCount,
    unAssignedAlertsCount,
    closedAlertsCount,
    staleAlertsCount,
  ]);

  const isOpenFilterApplied = !every(openFilters, (f) => isEmpty(f));
  const isAssignedFilterApplied = !every(assignedFilters, (f) => isEmpty(f));
  const isUnassignedFilterApplied = !every(unAssignedFilters, (f) => isEmpty(f));
  const isClosedFilterApplied = !every(closedFilters, (f) => isEmpty(f));
  const isStaleFilterApplied = !every(staleFilters, (f) => isEmpty(f));

  const tabs = [
    { label: 'Open Alerts', count: openAlertsCount, hidden: !openAlertsCount && !isOpenFilterApplied },
    {
      label: 'Alerts Assigned to me',
      count: assignedAlertsCount,
      hidden: !assignedAlertsCount && !isAssignedFilterApplied,
    },
    {
      label: 'Unassigned Alerts',
      count: unAssignedAlertsCount,
      hidden: !unAssignedAlertsCount && !isUnassignedFilterApplied,
    },
    {
      label: 'Closed Alerts',
      count: closedAlertsCount,
      hidden: !closedAlertsCount && !isClosedFilterApplied,
    },
    {
      label: (
        <div>
          <Tooltip
            place='bottom'
            className='-ml-1 mr-1 bg-slate-300/100 leading-3 text-gray-900'
            content='Inactive alerts are alerts created in the past but are no longer active due to reasons indicated in the audit trail for the respective alert'
            id='inactive-alert-tooltip'
            label='i'
          />
          Inactive Alerts
        </div>
      ),
      count: staleAlertsCount,
      hidden: !staleAlertsCount && !isStaleFilterApplied,
    },
  ];

  const alertsCount = [
    openAlertsCount,
    assignedAlertsCount,
    unAssignedAlertsCount,
    closedAlertsCount,
    staleAlertsCount,
  ][tab];
  const alertsData = [
    openAlertsData,
    assignedAlertsData,
    unAssignedAlertsData,
    closedAlertsData,
    staleAlertsData,
  ][tab];

  const onChange = (tab: number) => setTab(tab);

  // const onResolveAll = () => {
  //   setIsBulkUpdate(true);
  //   // wait for 10s for BE to resolve all alerts
  //   setTimeout(() => {
  //     const key = ['alertApi.getAlerts', id];
  //     queryClient.invalidateQueries(key);
  //     setIsBulkUpdate(false);
  //     toast.success('Resolved all open alerts');
  //   }, 10000);
  // };

  const handleChangeStage = (stage: IOption) => {
    setSelectedOption(stage);
    const foundStage = stageOptionsData.find((r) => r.label === stage.label);
    setSelectedOptionId(foundStage.id);
  };
  const handleChangeAssignee = (assignee: IOption) => {
    setSelectedOption(assignee);
    const foundAssignee = assignedToOptions.find((r) => r.email === assignee.label);
    setSelectedOptionId(foundAssignee.user_id);
  };

  const selectAlerts = (id) => {
    if (selectedAlerts?.includes(id)) {
      setSelectedAlerts(selectedAlerts.filter((i) => i !== id));
    } else {
      setSelectedAlerts([...selectedAlerts, id]);
    }
  };

  const selectAllAlerts = (force = false) => {
    if (force) {
      setSelectedAlerts(alertsData.map((alert) => alert.id));
    } else {
      if (selectedAlerts.length === alertsData.length) {
        setSelectedAlerts([]);
        setIsAllAlertsSelected(false);
      } else {
        setSelectedAlerts(alertsData.map((alert) => alert.id));
      }
    }
  };

  const handleSubmit = () => {
    if (comment) {
      if (isBulkUpdate) {
        const updatedFilters = { ...currentFilters };
        switch (tab) {
          case 0:
            updatedFilters.status = [0];
            break;
          case 1:
            updatedFilters.assigned_to = [state.userProfile?.id];
            break;
          case 2:
            updatedFilters.assigned_to = ['None'];
            break;
          case 3:
            updatedFilters.status = [closedStage?.id];
            break;
          default:
            break;
        }
        bulkAlertStatusUpdate({
          comment: comment,
          ids: isAllAlertsSelected ? [] : selectedAlerts,
          filters: isAllAlertsSelected && updatedFilters,
          updated_status: openModal === 'status' ? selectedOptionId : undefined,
          updated_assigned_to: openModal === 'assignee' ? selectedOptionId : undefined,
          is_stale: tab === 4 ? true : undefined,
        });
      } else {
        mutateAlert({
          type,
          id,
          status: openModal === 'status' ? selectedOptionId : undefined,
          assigned_to: openModal === 'assignee' ? selectedOptionId : undefined,
          alertId: selectedAlertId,
          comment: comment,
        });
      }
    } else {
      toast.error('Enter a comment');
    }
  };

  const isNoAlerts =
    !openAlertsCount &&
    !assignedAlertsCount &&
    !unAssignedAlertsCount &&
    !closedAlertsCount &&
    !staleAlertsCount &&
    !isLoading &&
    !isOpenFilterApplied &&
    !isAssignedFilterApplied &&
    !isUnassignedFilterApplied &&
    !isClosedFilterApplied &&
    !isStaleFilterApplied;

  const renderPagination = (query: UseInfiniteQueryResult<AxiosResponse<IAlertGetAlertsResponse>>) => {
    if (showLoading ? query.isLoading : false)
      return <Skeleton count={4} height={55} className='' containerClassName='mt-3 flex flex-col' />;
    return (
      <Pagination
        query={query}
        className={`${showFullHeight ? 'max-h-[70vh]' : 'max-h-96'} text-sm`}
        errorMessage='No alerts available'>
        {(alert) => (
          <Alert
            alert={alert}
            id={id}
            key={alert.id}
            type={type}
            setAlertDetails={setAlertDetails}
            selectAlerts={selectAlerts}
            selectedAlerts={selectedAlerts}
            setIsBulkUpdate={setIsBulkUpdate}
            setOpenModal={setOpenModal}
            setCurrentOption={setCurrentOption}
            setSelectedAlertId={setSelectedAlertId}
            getCustomOptionLabel={getCustomOptionLabel}
            getCustomLabelAssignee={getCustomLabelAssignee}
          />
        )}
      </Pagination>
    );
  };

  const getCustomOptionLabel = (option: IStageOptions) => {
    if (option.label === undefined) {
      return <StageBadge role={7} label='-NA-' />;
    }
    return <StageBadge role={stageColorMap[option?.id]} label={option?.label} />;
  };

  const getCustomLabelAssignee = (option: IOption) => {
    return (
      <UserBadge className='bg-gray-200 text-gray-800' label={option?.label ? option?.label : 'Unassigned'} />
    );
  };

  return (
    <div className='relative'>
      <div
        className={classNames('rounded-md border border-gray-200', {
          // 'pointer-events-none opacity-50': isBulkUpdate,
        })}>
        <Tab
          values={tabs}
          type='primary'
          listClassName='border-b-2 p-4 rounded-t-md'
          inactiveClassName='text-gray-800/30 border-gray-200 cursor-not-allowed'
          panelClassName='px-4'
          headerActions={
            !isNoAlerts && (
              <div className='flex'>
                <AlertFilter
                  filters={currentFilters}
                  onApply={onApplyFilter}
                  type={type}
                  disabled={selectedAlerts?.length > 0}
                  tab={tab}
                />
              </div>
            )
          }
          isLoading={showLoading ? undefined : isLoading}
          subHeader={
            <AlertAppliedFilters
              tab={tab}
              count={alertsCount}
              filters={currentFilters}
              selectedAlerts={selectedAlerts}
              isAllAlertsSelected={isAllAlertsSelected}
              selectedText={`${selectedAlerts.length} ${selectedAlerts.length > 1 ? 'alerts' : 'alert'} selected.`}
              setFilters={onApplyFilter}
              selectAllAlerts={selectAllAlerts}
              setSelectedAlerts={setSelectedAlerts}
              setIsAllAlertsSelected={setIsAllAlertsSelected}
              setOpenModal={setOpenModal}
              setIsBulkUpdate={setIsBulkUpdate}
              stageOptions={stageOptionsData}
              assigneeOptions={assignedToOptions}
            />
          }
          onChange={onChange}>
          {renderPagination(openAlertsQuery)}
          {renderPagination(assignedAlertsQuery)}
          {renderPagination(unAssignedAlertsQuery)}
          {renderPagination(closedAlertsQuery)}
          {renderPagination(staleAlertsQuery)}
        </Tab>
      </div>
      {/* {isBulkUpdate && (
        <div className='absolute inset-x-0 top-1/2 mx-auto flex w-80 text-center text-sm opacity-100'>
          Bulk resolve alerts will complete in few seconds
        </div>
      )} */}
      <UpdateStageorAssigneeModal
        bulkAction={isBulkUpdate}
        open={openModal !== ''}
        setOpen={setOpenModal}
        optionsData={openModal === 'status' ? stageOptionsData : assignedToOptionsData}
        currentOption={currentOption}
        selectedOption={selectedOption}
        setSelectedOption={openModal === 'status' ? handleChangeStage : handleChangeAssignee}
        handleSubmit={handleSubmit}
        setComment={setComment}
        isStageorAssignee={openModal === 'status'}
        comment={comment}
        getCustomOptionLabel={openModal === 'status' ? getCustomOptionLabel : getCustomLabelAssignee}
        setSearch={setSearch}
        isLoading={isLoadingUpdateStageorAssignee}
        assignedToOptionsQuery={assignedToOptionsQuery}
      />
    </div>
  );
};

export default Alerts;
