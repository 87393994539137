/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { alertApi, useAlertGetAllAlerts } from '../../../api/alert';
import AlertListFilter, {
  defaultAlertListFilters,
  IFilters,
} from '../../../components/AlertList/AlertListFilter';
import { useRouter } from '../../../modules/router/RouterProvider';
import { Checkbox, RiskBadge } from '../../../ui';
import Table from '../../../ui/components/Table/Table';
import { useScreenApi } from '../../../utils/helpers/apiHelpers';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import AlertListAppliedFilter from '../../../components/AlertList/AlertListAppliedFilter';
import IdentifierEllipsis from '../../../components/ui/components/Currency/IdentifierEllipsis';
import CurrencyBadge from '../../../components/ui/components/Badge/CurrencyBadge';
import { isArray, startCase } from 'lodash';
import Tab from '../../../components/ui/components/Tab/Tab';
import { ellipsis, getErrorMessage, getNextPageParam } from '../../../utils/helpers/helperFunctions';
import Popover from '../../../ui/components/Popover/Popover';
import CustomerAddressList from '../../../components/Address/AddressList/CustomerAddressList';
import { Date } from '../../../components/ui/components/Date';
import { useAuth } from '../../../modules/auth';
import { Search } from '../../../components/ui/components/Search';
import { useEffectOnce, useLocalStorage } from 'react-use';
import { useWorkspace } from '../../../utils/helpers/common';
import { isEqual } from 'lodash';
import ResolveMultipleAlertsModal from '../../../components/common/ResolveMultipleAlertsModal';
import { toast } from 'react-toastify';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { classifierApi } from '../../../api/classifier';
import UpdateStageorAssigneeModal from '../../../components/common/UpdateStageorAssigneeModal';
import { IWorkSpaceMembers } from '../../../api/dtos/workspaces';
import { useStagesListLiteGet } from '../../../api/stages';
import { useWorkSpaceGetWorkFlowMembers } from '../../../api/workspaces';
import { useDebounce } from 'use-debounce';
import { IOption } from '../../../components/ui/components/Select/SingleSelect';
import StageBadge from '../../../ui/components/Badges/StageBadge/StageBadge';
import { IStageOptions } from '../../../components/common/Alerts/Alert/AlertButtonPanel/AlertButtonPanel';
import { getColorMappingStageOptions } from '../../../utils/helpers/stageOptionsColor';
import UserBadge from '../../../ui/components/Badges/UserBadge/UserBadge';
import { IStageListLite } from '../../../api/dtos/stages';
import { AxiosError } from 'axios';

const AlertList = () => {
  const screenApi = useScreenApi();
  const queryClient = useQueryClient();
  const { navigate, getQueryParams } = useRouter();
  const {
    level,
    start_time,
    end_time,
    rule_id,
    rule_name,
    policy_type,
    policy_category,
    workflow_stage,
    assigned_to,
    assigned_to_name,
    tab,
  } = getQueryParams();
  const workspace = useWorkspace();

  const [filterStorage, setFilterStorage] = useLocalStorage(
    `compass.storage.alertFilters.${workspace.slug}`,
    null
  );
  const [filters, setFilters] = useState<IFilters>({
    level: level ? level.split(',').map(Number) : [],
    rule_id: rule_id && rule_id !== 'null' ? rule_id : null,
    rule_name: rule_name && rule_name !== 'null' ? rule_name : null,
    start_time: start_time && start_time !== 'null' ? start_time : null,
    end_time: end_time && end_time !== 'null' ? end_time : null,
    policy_category: policy_category && policy_category !== 'null' ? policy_category : null,
    policy_type: policy_type && policy_type !== 'null' ? Number(policy_type) : null,
    status: workflow_stage
      ? workflow_stage.split(',').map((val) => (val === 'None' ? val : Number(val)))
      : [],
    assigned_to: assigned_to ? assigned_to.split(',').map(Number) : [],
    assigned_to_name: assigned_to_name ? assigned_to_name.split(',').map(String) : [],
  });
  const selectedTab = tab ? parseInt(tab) : -1;
  const [selectedAlerts, setSelectedAlerts] = useState<number[]>([]);
  const [isAllAlertsSelected, setIsAllAlertsSelected] = useState(false);
  const [search, setSearch] = useState('');
  const [prevWorkspaceSlug, setPrevWorkspaceSlug] = useState(workspace.slug);
  const [currentTab, setCurrentTab] = useState(Number(tab) ? parseInt(tab) : 0);
  const [disableAllTabs, setDisableAllTabs] = useState(true);
  const [isResolveAlertsModalOpen, setIsResolveAlertsModalOpen] = useState(false);
  const [assigneeSearch, setAssigneeSearch] = useState('');
  const [debouncedSearch] = useDebounce(assigneeSearch, 500);
  const [selectedOption, setSelectedOption] = useState<IStageOptions>();
  const [openModal, setOpenModal] = useState('');
  const [selectedOptionId, setSelectedOptionId] = useState<number>();
  const [closedStage, setClosedStage] = useState<IStageListLite>();

  const [comment, setComment] = useState('');
  const { state } = useAuth();
  const [isQueryEnabled, setIsQueryEnabled] = useState({
    open: false,
    assigned_to_me: false,
    unassigned: false,
    closed: false,
    all: false,
  });
  const openAlertsQuery = useAlertGetAllAlerts(
    { ...filters, q: search, status: 0 },
    { enabled: isQueryEnabled.open || currentTab === 0 }
  );
  const myAlertsQuery = useAlertGetAllAlerts(
    { ...filters, q: search, assigned_to: state.userProfile?.id },
    {
      enabled: isQueryEnabled.assigned_to_me || currentTab === 1,
    }
  );
  const unassignedAlertsQuery = useAlertGetAllAlerts(
    { ...filters, q: search, assigned_to: 'None' },
    { enabled: isQueryEnabled.unassigned || currentTab === 2 }
  );
  const closedAlertsQuery = useAlertGetAllAlerts(
    { ...filters, q: search, status: closedStage?.id },
    {
      enabled: (isQueryEnabled.closed || currentTab === 3) && !!closedStage,
    }
  );
  const allAlertsQuery = useAlertGetAllAlerts(
    { ...filters, q: search },
    {
      enabled: isQueryEnabled.all || currentTab === 4,
    }
  );
  const ruleNamesQuery = useInfiniteQuery(
    ['ruleName', 'all'],
    ({ pageParam = 0 }) => classifierApi.getClassifiers({ rule_type: null, limit: 500, offset: pageParam }),
    {
      getNextPageParam,
    } as unknown
  );
  const assignedToOptionsQuery = useWorkSpaceGetWorkFlowMembers({
    q: debouncedSearch,
    is_active: true,
  });

  const { mutate: bulkAlertStatusUpdate, isLoading: isLoadingUpdateStageOrAssignee } = useMutation(
    alertApi.updateMultipleAlerts,
    {
      onSuccess: () => {
        const key = ['alertApi.getAllAlerts'];
        queryClient.invalidateQueries(key);
        toast.success('Alerts Updated Successfully');
        setOpenModal('');
        setComment('');
        setSelectedOption(null);
        setSelectedOptionId(null);
      },
      onError(err: AxiosError<{ error: string }>) {
        toast.error(getErrorMessage(err));
      },
    }
  );
  const [, assignedToOptions]: [number, IWorkSpaceMembers[]] = flattenInfiniteQueryResult(
    assignedToOptionsQuery?.data
  );
  const stagesListLiteQuery = useStagesListLiteGet();

  const getStageOptions = (stageData) => {
    return stageData?.map((r) => {
      return {
        id: r.id,
        value: r.name,
        label: r.name,
      };
    });
  };

  const getAssignedToOptions = (assignedToData: IWorkSpaceMembers[]) => {
    return assignedToData?.map((r) => {
      return {
        id: r.user_id,
        value: r.email,
        label: r.email,
      };
    });
  };

  const stageOptionsData = getStageOptions(stagesListLiteQuery?.data?.data?.results);
  const stageColorMap = getColorMappingStageOptions(stagesListLiteQuery?.data?.data?.results);
  const assignedToOptionsData = getAssignedToOptions(assignedToOptions);

  useEffect(() => {
    setClosedStage(stagesListLiteQuery?.data?.data.results.find((item) => item.is_final_stage));
  }, [stagesListLiteQuery]);

  useEffect(() => {
    if (openAlertsQuery.isFetched) {
      setIsQueryEnabled((prev) => ({ ...prev, assigned_to_me: true }));
    }
  }, [openAlertsQuery.isFetched]);

  useEffect(() => {
    if (myAlertsQuery.isFetched) {
      setIsQueryEnabled((prev) => ({ ...prev, unassigned: true }));
    }
  }, [myAlertsQuery.isFetched]);

  useEffect(() => {
    if (unassignedAlertsQuery.isFetched) {
      setIsQueryEnabled((prev) => ({ ...prev, closed: true }));
    }
  }, [unassignedAlertsQuery.isFetched]);

  useEffect(() => {
    if (closedAlertsQuery.isFetched) {
      setIsQueryEnabled((prev) => ({ ...prev, all: true }));
    }
  }, [closedAlertsQuery.isFetched]);

  useEffect(() => {
    if (allAlertsQuery.isFetched) {
      setIsQueryEnabled((prev) => ({ ...prev, open: true }));
    }
  }, [allAlertsQuery.isFetched]);

  const [allAlertsCount, allAlertsData] = flattenInfiniteQueryResult(allAlertsQuery?.data);
  const [openAlertsCount, openAlertsData] = flattenInfiniteQueryResult(openAlertsQuery?.data);
  const [unassignedAlertsCount, unassignedAlertsData] = flattenInfiniteQueryResult(
    unassignedAlertsQuery?.data
  );
  const [myAlertsCount, myAlertsData] = flattenInfiniteQueryResult(myAlertsQuery?.data);
  const [closedAlertsCount, closedAlertsData] = flattenInfiniteQueryResult(closedAlertsQuery?.data);
  const alertsQuery =
    currentTab < 0
      ? openAlertsQuery
      : [openAlertsQuery, myAlertsQuery, unassignedAlertsQuery, closedAlertsQuery, allAlertsQuery][
          currentTab
        ];
  const alertsCount =
    currentTab < 0
      ? openAlertsCount
      : [openAlertsCount, myAlertsCount, unassignedAlertsCount, closedAlertsCount, allAlertsCount][
          currentTab
        ];
  const alertsData =
    currentTab < 0
      ? openAlertsData
      : [openAlertsData, myAlertsData, unassignedAlertsData, closedAlertsData, allAlertsData][currentTab];

  const formatLocalStorageValue = (unformattedFilter: IFilters): IFilters => {
    const val = {};
    Object.keys(unformattedFilter).forEach((key) => {
      if (unformattedFilter[key] === 'null') {
        val[key] = null;
      } else {
        val[key] = unformattedFilter[key];
      }
    });
    return val as IFilters;
  };

  const syncFilters = (newFilters: IFilters, tab_id?: number) => {
    if (state?.userProfile.email) {
      const newSearchParams = new URLSearchParams();
      const tabValue = tab_id !== undefined && tab_id !== null ? tab_id.toString() : currentTab?.toString();
      newSearchParams.set('tab', tabValue);
      Object.keys(newFilters).forEach((key) => {
        if (newFilters[key] !== null && (!Array.isArray(newFilters[key]) || newFilters[key].length !== 0)) {
          newSearchParams.set(key, newFilters[key].toString());
        }
      });
      setFilterStorage(newFilters);
      navigate(`/alerts`, Object.fromEntries(newSearchParams));
      setDisableAllTabs(false);
    }
  };

  useEffectOnce(() => {
    workspace.workspaces.forEach((w) => {
      if (!localStorage.getItem(`compass.storage.alertFilters.${w.slug}`)) {
        localStorage.setItem(
          `compass.storage.alertFilters.${w.slug}`,
          JSON.stringify(defaultAlertListFilters)
        );
      }
    });
  });

  // set filters on url priority
  useEffect(() => {
    if (isEqual(defaultAlertListFilters, filters)) {
      const localStorageFilters = filterStorage
        ? formatLocalStorageValue(filterStorage)
        : defaultAlertListFilters;
      if (localStorageFilters.rule_id === undefined || isArray(localStorageFilters.rule_name)) {
        localStorageFilters.rule_id = null;
        localStorageFilters.rule_name = null;
      }
      setFilters(localStorageFilters);
      syncFilters(localStorageFilters);
    } else {
      setFilterStorage(filters);
      setDisableAllTabs(false);
    }
  }, []);

  // reset filters when switching workspace
  useEffect(() => {
    if (prevWorkspaceSlug !== workspace.slug && workspace.slug) {
      setPrevWorkspaceSlug(workspace.slug);
      setFilters(defaultAlertListFilters);
    }
  }, [workspace.slug]);

  // switch to all tab if open alerts count is 0
  useEffect(() => {
    if (openAlertsQuery.isSuccess && openAlertsCount === 0) {
      onChangeTab(4, filters);
    }
  }, [openAlertsQuery.isSuccess]);

  const getCustomOptionLabel = (option: IStageOptions) => {
    if (option.label === undefined) {
      return <StageBadge role={7} label='-NA-' />;
    }
    return <StageBadge role={stageColorMap[option?.id]} label={option?.label} />;
  };

  const getCustomLabelAssignee = (option: IOption) => {
    return (
      <UserBadge className='bg-gray-200 text-gray-800' label={option?.label ? option?.label : 'Unassigned'} />
    );
  };

  const headerData = [
    <Checkbox
      key={0}
      className='w-0 rounded'
      onChange={() => selectAllAlerts()}
      checked={selectedAlerts.length === alertsData.length && alertsData.length !== 0}
    />,
    'Alert ID',
    <Popover
      key={0}
      isBasic
      referenceContent='Identifier'
      popoverContent='Identifier of Address/Transaction or Customer id'
    />,
    'Blockchain',
    'Customer ID',
    'Policy Type & Category',
    'Rule Name',
    <>
      Last Created at
      {/* <Tooltip className='z-99 bg-slate-300 text-gray-900' content='' id='last-created-at-label' label='i' /> */}
    </>,
    'Risk level',
    'Status',
  ];

  const rows = alertsData?.map((alert) => {
    const entity = alert.content_object_fields;
    return {
      id: alert.id,
      data: [
        <Checkbox
          key={alert.id}
          className='w-0 rounded'
          onChange={(e) => {
            e.stopPropagation();
            selectAlert(alert.id);
          }}
          checked={selectedAlerts.includes(alert.id)}
        />,
        alert.id,
        <IdentifierEllipsis
          copyable
          key={alert.id}
          clickable
          identifier={entity.identifier || entity.customer_id}
        />,
        <CurrencyBadge key={alert.id} currency={entity.currency} />,
        alert.entity_type !== 'customer' ? <CustomerAddressList key={alert.id} address={entity} /> : null,
        startCase(alert.entity_type) + ' > ' + alert.rule_register.policy_category_verbose,
        <Popover
          key={alert.id}
          isBasic
          referenceContent={ellipsis(alert.rule_register.rule_name || '', 25)}
          popoverContent={alert.rule_register.rule_name}
        />,
        <Date key={alert.id} date={alert.opened_at} type='duration' tz={state.userProfile.timezone} />,
        <RiskBadge key={alert.id} risk={alert.level} grayScale={alert.is_stale || alert.status === 1} />,
        getCustomOptionLabel({
          label: alert.workflow_stage?.name,
          value: alert.workflow_stage?.name,
          id: alert.workflow_stage?.id,
        }),
        // <AlertStatusBadge key={alert.id} type={!alert.is_stale ? alert.status : 3} hideStatusLabel />,
      ],
      onClick: async () => {
        if (alert.entity_type === 'customer') {
          navigate(`/customers/${entity.customer_id}`, { alert: alert.id.toString() });
          return;
        }
        const entityLong = alert.entity_type === 'address' ? 'addresses' : 'transactions';
        const result = await screenApi(
          {
            identifier: entity.identifier,
            currency: entity.currency,
            entityType: entityLong,
            customer_id: entity.customer_id,
            type: entity.type,
            address: entity.deposit_address,
          },
          false
        );
        navigate(`/${entityLong}/${result.id}`, { alert: alert.id.toString() });
      },
    };
  });

  const tabs = [
    {
      label: 'Open',
      count: openAlertsCount,
    },
    {
      label: 'Assigned to me',
      count: myAlertsCount,
    },
    {
      label: 'Unassigned',
      count: unassignedAlertsCount,
    },
    {
      label: 'Closed',
      // label: (
      //   <div>
      //     <Tooltip
      //       place='bottom'
      //       className='-ml-1 mr-1 bg-slate-300/100 leading-3 text-gray-900'
      //       content='Inactive alerts are alerts created in the past but are no longer active due to reasons indicated in the audit trail for the respective alert'
      //       id='inactive-alert-tooltip'
      //       label='i'
      //     />
      //     Inactive
      //   </div>
      // ),
      count: closedAlertsCount,
    },
    {
      label: 'All',
      count: allAlertsCount,
    },
  ];

  const selectAlert = (id) => {
    if (selectedAlerts?.includes(id)) {
      setSelectedAlerts(selectedAlerts.filter((i) => i !== id));
    } else {
      setSelectedAlerts([...selectedAlerts, id]);
    }
  };

  const selectAllAlerts = (force = false) => {
    if (force) {
      setSelectedAlerts(alertsData.map((alert) => alert.id));
    } else {
      if (selectedAlerts.length === alertsData.length) {
        setSelectedAlerts([]);
        selectAllAlerts(false);
      } else {
        setSelectedAlerts(alertsData.map((alert) => alert.id));
      }
    }
  };

  const disableAllQueries = () => {
    setIsQueryEnabled({
      open: false,
      assigned_to_me: false,
      unassigned: false,
      closed: false,
      all: false,
    });
  };

  const onApply = (filters) => {
    setFilters(filters);
    syncFilters(filters, currentTab);
    disableAllQueries();
  };

  const onReset = () => {
    setFilters(defaultAlertListFilters);
    syncFilters(defaultAlertListFilters, currentTab);
    disableAllQueries();
  };

  const onChangeTab = (index: number, newFilters?: IFilters) => {
    setCurrentTab(index);
    setSelectedAlerts([]);
    setIsAllAlertsSelected(false);
    if (newFilters) {
      syncFilters(newFilters, index);
    } else {
      syncFilters(filters, index);
    }
  };

  const handleChangeStage = (stage: IOption) => {
    setSelectedOption(stage);
    const foundStage = stageOptionsData.find((r) => r.label === stage.label);
    setSelectedOptionId(foundStage.id);
  };
  const handleChangeAssignee = (assignee: IOption) => {
    setSelectedOption(assignee);
    const foundAssignee = assignedToOptions.find((r) => r.email === assignee.label);
    setSelectedOptionId(foundAssignee.user_id);
  };

  const handleSubmit = () => {
    if (comment) {
      bulkAlertStatusUpdate({
        comment: comment,
        ids: isAllAlertsSelected ? [] : selectedAlerts,
        q: isAllAlertsSelected ? search : undefined,
        filters: isAllAlertsSelected ? filters : undefined,
        updated_status: openModal === 'status' ? selectedOptionId : undefined,
        updated_assigned_to: openModal === 'assignee' ? selectedOptionId : undefined,
      });
    } else {
      toast.error('Enter a comment');
    }
  };

  return (
    <div>
      <Table
        title='Alerts'
        headerData={headerData}
        rows={rows}
        count={`Showing ${rows?.length} of ${alertsCount ?? 0} results`}
        heightOffset={26.5}
        tab={tab}
        headerActionsLeft={
          <Tab
            values={tabs}
            onChange={onChangeTab}
            type={'primary'}
            defaultIndex={currentTab}
            showCountLoading
            disableAllTabs={disableAllTabs}
            changeTab={currentTab ?? null}
          />
        }
        headerActions={
          <Search
            minChars={3}
            value={search}
            setValue={(q) => {
              setSearch(q);
              disableAllQueries();
            }}
            type='button'
          />
        }
        filterComponent={
          <AlertListFilter
            filters={filters}
            onApply={onApply}
            onReset={onReset}
            ruleNamesQuery={ruleNamesQuery}
            disabled={selectedAlerts?.length > 0}
            tab={currentTab}
          />
        }
        appliedFilters={
          <AlertListAppliedFilter
            count={alertsCount}
            filters={filters}
            selectedAlerts={selectedAlerts}
            isAllAlertsSelected={isAllAlertsSelected}
            selectedText={`${selectedAlerts.length} ${selectedAlerts.length > 1 ? 'alerts' : 'alert'} selected.`}
            setFilters={onApply}
            selectAllAlerts={selectAllAlerts}
            setSelectedAlerts={setSelectedAlerts}
            setIsAllAlertsSelected={setIsAllAlertsSelected}
            setOpenModal={setOpenModal}
            tab={currentTab}
            stageOptions={stagesListLiteQuery?.data?.data?.results}
          />
        }
        {...buildInfiniteQueryTableProps(alertsQuery)}
        isLoading={!alertsQuery.isFetched && !alertsQuery.isFetchingNextPage}
        isSelectable
      />
      <ResolveMultipleAlertsModal
        type='alert'
        isOpen={isResolveAlertsModalOpen}
        onClose={() => setIsResolveAlertsModalOpen(false)}
        selectedEntities={selectedAlerts}
        isAllEntitiesSelected={isAllAlertsSelected}
        refetchList={() => {
          setSelectedAlerts([]);
          setIsAllAlertsSelected(false);
          alertsQuery.refetch();
          unassignedAlertsQuery.refetch();
        }}
        selectedTab={selectedTab}
        filters={filters}
        searchText={search}
      />
      <UpdateStageorAssigneeModal
        bulkAction
        open={openModal !== ''}
        setOpen={setOpenModal}
        optionsData={openModal === 'status' ? stageOptionsData : assignedToOptionsData}
        selectedOption={selectedOption}
        setSelectedOption={openModal === 'status' ? handleChangeStage : handleChangeAssignee}
        handleSubmit={handleSubmit}
        setComment={setComment}
        isStageorAssignee={openModal === 'status'}
        comment={comment}
        getCustomOptionLabel={openModal === 'status' ? getCustomOptionLabel : getCustomLabelAssignee}
        setSearch={setAssigneeSearch}
        isLoading={isLoadingUpdateStageOrAssignee}
        assignedToOptionsQuery={assignedToOptionsQuery}
      />
    </div>
  );
};

export default AlertList;
