import * as React from 'react';
import { ITransactionResponse } from '../../../api/dtos/transaction';
import CurrencyDeprecationAlert from '../../common/CurrencyDeprecationAlert';
import Alerts from '../../common/Alerts';
import InvestigationTree from '../../AddressTransaction/InvestigationTree/InvestigationTree';
import { Card } from '../../ui/components/Card';
import CounterpartySummary from '../../AddressTransaction/CounterpartySummary';
import TransactionSummary from './TransactionSummary/TransactionSummary';
import FireblocksFrozenNote from './FireblocksFrozenNote';
// import StageAndAssignedWorkSpace from '../../common/StageAndAssignedWorkspace';
import { Transition } from '@headlessui/react';
import AlertDetails from '../../common/Alerts/Alert/AlertDetails';
import Skeleton from 'react-loading-skeleton';
import { useRouter } from '../../../modules/router/RouterProvider';

interface Props {
  transaction: ITransactionResponse;
  setAlerts: (alerts: number) => void;
  setCustomerAddresses: (addresses: string[]) => void;
  alertDetails: number;
  setAlertDetails: (alert: number) => void;
}

const Overview: React.FC<Props> = ({
  transaction,
  setAlerts,
  setCustomerAddresses,
  alertDetails,
  setAlertDetails,
}) => {
  const { navigate } = useRouter();
  return (
    <>
      <Transition
        as='div'
        show={!!alertDetails}
        enter='transition-opacity duration-300'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity duration-150'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'>
        {alertDetails ? (
          <AlertDetails
            alert={alertDetails}
            type='transaction'
            id={transaction.id}
            onClose={() => {
              navigate('', { alert: '' });
              setAlertDetails(null);
            }}
            transaction={transaction}
          />
        ) : (
          <Skeleton count={20} />
        )}
      </Transition>
      <Transition
        as='div'
        show={!alertDetails}
        enter='transition-opacity duration-300'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity duration-150'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'>
        <div className='flex flex-col gap-3'>
          <CurrencyDeprecationAlert currency={transaction.currency} />
          <TransactionSummary transaction={transaction} setCustomerAddresses={setCustomerAddresses} />
          {/* <StageAndAssignedWorkSpace
            type='transactions'
            id={transaction.id}
            assigned_to_name={transaction?.assigned_to}
            stage_name={transaction?.workflow_stage?.name}
            next_stages={transaction?.workflow_stage?.next_stages}
            stage_id={transaction?.workflow_stage?.id}
            is_final_stage={transaction?.workflow_stage?.is_final_stage}
          /> */}
          {transaction?.is_frozen_on_fireblocks && <FireblocksFrozenNote id={transaction.id} />}
          <Alerts
            id={transaction.id}
            type='transaction'
            setAlerts={setAlerts}
            setAlertDetails={setAlertDetails}
          />

          <CounterpartySummary transaction={transaction} type='transaction' />
          <Card title='INVESTIGATION MODE' containerClassName='border border-gray-200'>
            <InvestigationTree
              originType='transaction'
              identifier={transaction.identifier}
              transaction={transaction}
            />
          </Card>
        </div>
      </Transition>
    </>
  );
};

export default Overview;
